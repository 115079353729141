
import api from '@/services/api'
import format from 'date-fns/format'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { User, UserRequest } from '@/types/User'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { Group } from '@/types/Groups'
import { DropdownItem, PaginationBody } from '@/types/Misc'
import { UserType } from '@/enums/UserType'
import { useRouter } from 'vue-router'
import { AuditElement } from '@/types/Audit'
import { AxiosResponse } from 'axios'
import { SubscriptionDetailResponseModel } from '@/types/Voucher'
import { toastError } from '@/helpers/error-handling'

export default defineComponent({
  props: ['id'],
  components: {},
  setup(props) {
    const router = useRouter()
    const confirm = useConfirm()
    const toast = useToast()
    const userTypeEnum = UserType
    const userTypeOptions = Object.keys(userTypeEnum).map(key => ({
      label: userTypeEnum[key as keyof typeof userTypeEnum], value: key
    } as DropdownItem))
    
    const userStatus = ref<string>('')

    const user = ref<User>({} as User)
    const groups = ref<Group[]>([])
    const audit = ref<AuditElement[]>([])
    const subscriptions = ref<SubscriptionDetailResponseModel>({} as SubscriptionDetailResponseModel)

    const statusIcon = computed(() => {
      switch (userStatus.value) {
        case 'active':
          return 'check_circle'
        default:
          return 'remove_circle'
      }
    })

    onMounted(() => {
      const body: PaginationBody = {
        pageSize: 100,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put('manager/group/filter', body)
        .then(res => {
          groups.value = res?.data.data.list
        })
        .catch((err: any) => toastError(toast, err))

      api.get(`manager/user/${props.id}`)
        .then(res => {
          user.value = res?.data.data
          userStatus.value = user.value.isRegistrationCompleted ? 'active' : 'inactive'
          fetchAudit()
          fetchSubscriptions()
        })
        .catch((err: any) => toastError(toast, err))
    })

    const fetchAudit = () => {
      const body: PaginationBody = {
        pageSize: 3,
        pageNumber: 1,
        searchValue: '',
        orderBy: '',
        descending: true
      }
      api.put(`manager/audit/user/${props.id}?logType=Login`, JSON.stringify(body))
        .then(res => audit.value = res?.data.data.list)
        .catch((err: any) => toastError(toast, err))
    }

    const fetchSubscriptions = () => {
      api.get(`manager/subscription/${props.id}`)
        .then((res: AxiosResponse) => {
          subscriptions.value = res?.data.data
        })
        .catch((err: any) => toastError(toast, err))
    }

    const returnDate = (ts: number | undefined): string => {
      return ts ? format(new Date(ts * 1000), 'dd-MM-yyyy, H:mm:ss') : '-'
    }

    const returnDateShort = (ts: number | undefined) => {
      return ts ? format(new Date(ts * 1000), 'dd-MM-yyyy') : '-'
    }

    const handleSubmit = (): void => {
      const newUser: UserRequest = {
        externalId: user.value.externalId,
        groupExternalId: user.value.group.externalId,
        userType: user.value.userType,
        isTester: user.value.isTester,
        phoneNumber: user.value.phoneNumber,
        email: user.value.email,
        name: user.value.name
      } as UserRequest

      api.put('manager/user', JSON.stringify(newUser))
        .then(() => router.push('/users/all'))
        .catch((err: any) => toastError(toast, err))
    }

    const deleteUser = (id: string) => {
      confirm.require({
        message: 'Are you sure you want to delete selected users?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          api.delete(`manager/user/${id}`).then(() => {
            router.push('/users/all')
          }).catch((err: any) => toastError(toast, err))
        }
      })
    }

    const goToLog = () => {
      router.push(`/user-details/${props.id}/audit`)
    }


    const resetTherapy = (id: string) => {
      confirm.require({
        message: 'Are you sure you want to reset this users\'s therapy?',
        header: 'Warning',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          api.delete(
            `manager/progress/${id}`)
          .then(() => {
            toast.add({ severity: 'info', summary: 'Therapy has been reset.', life: 3000 })
          })
          .catch((err: any) => {
            if (err?.response?.status === 404 && err?.response?.data?.errors[0]?.code === 'NotFound') {
              toastError(toast, err, "User has no therapy progress.")
              return false
            }
            toastError(toast, err)
          })
        }
      })
    }

    return { 
      audit,
      groups,
      statusIcon,
      subscriptions,
      user,
      userStatus,
      userTypeOptions,
      userTypeEnum,
      handleSubmit,
      deleteUser,
      returnDate,
      returnDateShort,
      goToLog,
      resetTherapy
    }
  }
})
